.App {
  text-align: center;
}

.App-logo {
  height: flex;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color:red;
}

.App-link {
  color: #09d3ac;
}


.textbox {
	border: 1px dashed #000000;
	outline:0;
	height:25px;
	width: 275px;
}

.primary {
  color: orange;
}

